import { IOrganization, OrgUtils } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { RRuleManager, ScheduledEvent } from "@smartrr/shared/entities/Schedule/RRuleManager";

export const getNextDelivery = (
  cps: IPurchaseStateWithCustomerRelationship | undefined,
  organization: IOrganization | null
): ScheduledEvent | undefined => {
  if (!cps?.schedule || !organization) {
    return undefined;
  }
  const billingDays = OrgUtils.getOrgBillingDays(organization);
  const rruleManager = RRuleManager.fromJson(cps.schedule, OrgUtils.getBillingSchedule(organization));

  return rruleManager.getFutureScheduledDeliveries(1, billingDays)[0];
};
