import { IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { SubscriptionDeliveryMethod } from "@smartrr/shared/shopifyGraphQL/api";
import { formatAddressInline } from "@smartrr/shared/utils/formatAddressInline";

export const getDeliveryAddress = (
  deliveryMethod?: SubscriptionDeliveryMethod,
  fallbackAddress?: IMailingAddressJson
) => {
  if (!deliveryMethod) {
    return formatAddressInline(fallbackAddress) ?? "";
  }
  switch (deliveryMethod.__typename) {
    case "SubscriptionDeliveryMethodLocalDelivery": {
      return formatAddressInline(deliveryMethod?.address ?? fallbackAddress);
    }
    case "SubscriptionDeliveryMethodPickup": {
      return formatAddressInline(deliveryMethod?.pickupOption?.location?.address ?? fallbackAddress);
    }
    case "SubscriptionDeliveryMethodShipping": {
      return formatAddressInline(deliveryMethod?.address ?? fallbackAddress);
    }
  }
};
