import { SubscriptionDeliveryMethod } from "@smartrr/shared/shopifyGraphQL/api";

export const getDeliveryMethodTitle = (deliveryMethod?: SubscriptionDeliveryMethod) => {
  if (!deliveryMethod) {
    return undefined;
  }
  switch (deliveryMethod.__typename) {
    case "SubscriptionDeliveryMethodLocalDelivery": {
      return "Local Delivery";
    }
    case "SubscriptionDeliveryMethodPickup": {
      return "Pickup";
    }
    case "SubscriptionDeliveryMethodShipping": {
      return deliveryMethod?.shippingOption?.title ?? "Shipping";
    }
  }
};
