import { isNumber } from "lodash";

import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import { ISchedule } from "@smartrr/shared/entities/Schedule";

export const isCPSPrepaid = (schedule: ISchedule | null | undefined) => {
  if (!schedule) {
    return false;
  }

  return schedule.paymentFrequencyMultiple > 1;
};

export const isUpcomingOrderCPSPrepaid = (paymentMultipleDueOnDate: number) => {
  return paymentMultipleDueOnDate <= 0;
};

export const hasCPSRemainingOrders = (cps: IPurchaseState) => {
  return getCPSRemainingOrders(cps) > 0;
};

export const getCPSRemainingOrders = (cps: IPurchaseState) => {
  if (cps.schedule) {
    const schedule = cps.schedule;
    const isPrepaid = isCPSPrepaid(schedule);

    if (isPrepaid && isNumber(schedule.orderCycleIndex) && isNumber(schedule.paymentFrequencyMultiple)) {
      const numberOfRemainingOrders = Math.max(
        0,
        schedule.paymentFrequencyMultiple - 1 - schedule.orderCycleIndex
      );
      return cps.nextOrderDate ? numberOfRemainingOrders : 0;
    }
  }
  return 0;
};

export const isLastBillingCycleOfCPS = (schedule: ISchedule) => {
  return schedule.maxCycles ? schedule.maxCycles <= (schedule.totalBillingCycles ?? 0) : false;
};

export const isCPSInPrepaidBillingCycle = (isPrepaid: boolean, paymentMultipleDueOnDate: number | undefined) => {
  return isPrepaid && paymentMultipleDueOnDate != null ? paymentMultipleDueOnDate > 0 : false;
};

export const isCPSRecentlyMigratedPrepaid = (cps: IPurchaseState): boolean => {
  return (
    isCPSPrepaid(cps.schedule) && cps.schedule.totalBillingCycles === 1 && cps.externalSubscriptionStatus != null
  );
};
