import { isEmpty, isNil } from "lodash";

import { IMailingAddress, IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { IShopifyLikeMailingAddress } from "@smartrr/shared/interfaces/Address";
import { LocationAddress, SubscriptionMailingAddress } from "@smartrr/shared/shopifyGraphQL/api";

export function formatAddressInline(
  mailingAddress?: IMailingAddress | IMailingAddressJson | SubscriptionMailingAddress | LocationAddress | null
): string | undefined {
  if (!mailingAddress) {
    return;
  }
  let printableAddress = "";
  if (mailingAddress.address1) {
    printableAddress += mailingAddress.address1;
  }
  if (mailingAddress.address2) {
    printableAddress += (printableAddress.length > 0 ? " " : "") + mailingAddress.address2;
  }

  const parts: string[] = [];
  for (const addressPart of [
    printableAddress,
    mailingAddress.city,
    mailingAddress.province ?? mailingAddress.provinceCode,
    mailingAddress.zip,
    mailingAddress.countryCode,
  ]) {
    if (!isNil(addressPart) && !isEmpty(addressPart)) {
      parts.push(addressPart);
    }
  }
  return parts.join(", ");
}

export function formatGiftingAddressInline(giftingAdress?: IShopifyLikeMailingAddress): string {
  if (isNil(giftingAdress)) {
    return "";
  }
  let printableAddress = "";
  if (giftingAdress.firstName && giftingAdress.lastName) {
    printableAddress += giftingAdress.firstName + " " + giftingAdress.lastName + " ";
  }
  if (giftingAdress.address1) {
    printableAddress += giftingAdress.address1;
  }
  if (giftingAdress.address2) {
    printableAddress += (printableAddress.length > 0 ? " " : "") + giftingAdress.address2;
  }

  const parts: string[] = [];
  for (const addressPart of [
    printableAddress,
    giftingAdress.city,
    giftingAdress.province ?? giftingAdress.provinceCode,
    giftingAdress.zip,
    giftingAdress.countryCode,
  ]) {
    if (!isNil(addressPart) && !isEmpty(addressPart)) {
      parts.push(addressPart);
    }
  }
  return parts.join(", ");
}
