export const getFormattedWarningText = (errorMessage: string | undefined | null) => {
  if (!errorMessage) {
    return "Transaction failure";
  }
  if (errorMessage.includes("phone number")) {
    return "Missing phone number";
  }
  if (errorMessage.includes("discount")) {
    return "Invalid discount code";
  }
  if (errorMessage.includes("variant is invalid")) {
    return "Deleted product variant";
  }
  if (errorMessage.includes("one line")) {
    return "No variant ID found";
  }
  if (errorMessage.includes("shipping address")) {
    return "Invalid shipping address";
  }
  if (errorMessage.includes("selling plan")) {
    return "Missing subscription plan";
  }
  return "Transaction failure";
};
