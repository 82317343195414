import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";

import { navigateToSubscriptionDetails } from "@vendor-app/utils/navigateWithShopInQuery";

export const openSubscriptionDetails = (cps: IPurchaseStateWithCustomerRelationship) => {
  const custRelShopifyId = cps.custRel?.shopifyId;
  const subscriptionShopifyId = cps.shopifyId;

  if (!cps.custRel) {
    return;
  }

  if (!custRelShopifyId || !subscriptionShopifyId) {
    return navigateToSubscriptionDetails(cps.id);
  }

  navigateToSubscriptionDetails(subscriptionShopifyId);
};
